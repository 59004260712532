<template>
  <div class="mt-1">
    <h5>Пін-код:</h5>
    <div class="d-flex align-items-center">
      <b-form-input
          v-model="account.currentCard.pin"
          class="pin-input"
          :disabled="disabledAccount"
      >
      </b-form-input>
      <b-button :variant="disabledAccount ? '' : 'success'"
                :disabled="disabledAccount"
                @click="changePin()">
        Зберегти
      </b-button>
    </div>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {BButton, BFormInput} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BFormInput
  },
  props: {
    account: {
      type: Object,
      default: () => {
      },
      required: true,
    },
    profileId: {
      type: String | Number,
      required: true,
    },
    disabledAccount: {
      type: Boolean,
      default: true
    },
  },
  setup(props) {
    const toast = useToast();
    const { account, profileId } = props;

    const changePin = async () => {

      const response = await axiosIns
          .put(`/business/drivers/${profileId}/cards/${account.typeAccount}/pin`, {
            pin: account.currentCard.pin,
          })
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Збережено',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Помилка',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            return false
          })
    };

    return { changePin };
  },
};
</script>