<template>
  <div>
    <app-collapse class="mt-2">

      <app-collapse-item title="Доступні види палива">
        <div class="d-flex flex-wrap mt-1">
          <div class="mb-1 mr-3 fuel-checkbox-wrapper" v-for="fuel in fuels" :key="fuel.value">
            <b-form-checkbox
                class="custom-control-success"
                v-model="account.currentCard.fuels"
                :disabled="disabledAccount"
                :value="fuel.value"
            >
              {{ getFuelsTitle(fuel.title) }}
            </b-form-checkbox>
          </div>
        </div>

        <b-button
            :variant="disabledAccount ? '' : 'success'"
            :disabled="disabledAccount"
            @click="saveLimitsFuels(account.currentCard.limits, account.currentCard.fuels, driverData.profileId, account.typeAccount)">
          Зберегти
        </b-button>
      </app-collapse-item>

    </app-collapse>
  </div>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {BButton, BFormCheckbox} from "bootstrap-vue";
import { useSaveLimits } from "@/views/apps/driver/drivers-view/useSaveLimits";
import { useFuels } from "@/composition/extra/useFuels";

export default {
  components: {
    BFormCheckbox,
    BButton,
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    account: {
      type: Object,
      default: () => {},
      required: true,
    },
    driverData: {
      type: Object,
      required: true,
    },
    disabledAccount: {
      type: Boolean,
      default: true
    },
  },
  setup() {
    const { saveLimitsFuels } = useSaveLimits();
    const { fuels, getFuelsTitle } = useFuels();

    return { saveLimitsFuels, fuels, getFuelsTitle };
  },
};
</script>