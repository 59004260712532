import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useBalancesList(driverId) {
    // Use toast
    const toast = useToast()

    const refInvoiceListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        { key: 'id', label: '#', sortable: false },
        { key: 'invoiceStatus', sortable: false },
        { key: 'driver', label: 'Водій', sortable: false },
        { key: 'manager', label: 'Менеджер', sortable: false },
        { key: 'amount', label: 'Сума', sortable: false, formatter: val => `${(val / 100).toFixed(2)} грн` },
        { key: 'typeAccount', label: "Тип рахунку", sortable: false },
        { key: 'isExecuted', label: "Статус", sortable: false },
        { key: 'createdAt', label: 'Створений', sortable: false },
        { key: 'executedAt', label: 'Виконаний', sortable: false },
        { key: 'actions', label: 'Дії' },
    ]
    const perPage = ref(10)
    const totalBalances = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)
    const driverFilter = ref(null)
    const cashTypeFilter = ref(null)

    const dataMeta = computed(() => {
        const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalBalances.value,
        }
    })

    const refetchData = () => {
        refInvoiceListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, statusFilter, driverFilter, cashTypeFilter], () => {
        refetchData()
    })

    const fetchBalances = (ctx, callback) => {
        store
            .dispatch('app-driver/fetchBalances', {
                q: searchQuery.value,
                size: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                filterProfileId: driverId,
            })
            .then(response => {
                const invoices = response.data.items
                const total = response.data.cursor.totalItems

                callback(invoices)
                totalBalances.value = total
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching invoices' list",
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }



    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveInvoiceStatusVariantAndIcon = directionId => {
        if (directionId == 2) return { variant: 'danger', icon: 'CheckCircleIcon' }
        if (directionId == 1) return { variant: 'success', icon: 'SendIcon' }
        return { variant: 'secondary', icon: 'XIcon' }
    }

    const resolveClientAvatarVariant = directionId => {
        if (directionId == 2) return 'primary'
            // if (directionId === 'Paid') return 'danger'
        if (directionId == 1) return 'secondary'
            // if (directionId === 'Draft') return 'warning'
            // if (directionId === 'Sent') return 'info'
            // if (directionId === 'Past Due') return 'success'
        return 'primary'
    }

    const resolveCashVariant = cashType => {
        if (cashType == '1') return 'warning'
        if (cashType == '2') return 'info'
        return 'primary'
    }

    const resolveCash = cashType => {
        if (cashType == '1') return 'Готівковий'
        if (cashType == '2') return 'Безготівковий'
    }

    const resolveStatusVariant = isExecuted => {
        if (isExecuted == true) return 'success'
        if (isExecuted == false) return 'primary'
        return 'primary'
    }

    const resolveStatus = isExecuted => {
        if (isExecuted == true) return 'Виконаний'
        if (isExecuted == false) return 'В процесі'
    }

    const statusOptions = [
        { label: 'Виконаний', value: true },
        { label: 'В процесі', value: false },
    ]

    const cashTypeOptions = [
        { label: 'Готівковий', value: 1 },
        { label: 'Безготівковий', value: 2 },
    ]

    return {
        fetchBalances,
        tableColumns,
        perPage,
        currentPage,
        totalBalances,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refInvoiceListTable,

        statusFilter,
        driverFilter,
        cashTypeFilter,

        statusOptions,
        cashTypeOptions,

        resolveInvoiceStatusVariantAndIcon,
        resolveClientAvatarVariant,

        resolveCashVariant,
        resolveCash,

        resolveStatusVariant,
        resolveStatus,

        refetchData,
    }
}