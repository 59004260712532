<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="driverData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching driver data
      </h4>
      <div class="alert-body">
        No driver found with this driver id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-drivers-list'}"
        >
          Driver List
        </b-link>
        for other drivers.
      </div>
    </b-alert>

    <template>

      <b-card
        no-body
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Записи</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refFuelListTable"
          :items="fetchInvoices"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Відповідних записів не знайдено"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >

          <template #head(fuelStatus)>
            <feather-icon
              icon="TrendingUpIcon"
              class="mx-auto"
            />
          </template>

          <!-- Column: Id -->
          <template #cell(id)="data">
            <b-link
              class="font-weight-bold"
              @click="showModal(data.item)"
            >
              #{{ data.item.number }}
            </b-link>
          </template>

          <!-- Column: Driver -->
          <template #cell(driver)="data">
            <b-media 
              vertical-align="center" class="align-items-center" 
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.driverFullName)"
                  :variant="`light-${resolveClientAvatarVariant(data.item.directionId)}`"
                />
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.driverFullName ? data.item.driverFullName : '-' }}
              </span>
            </b-media>
          </template>

          <!-- Column: Client -->
          <template #cell(issuerName)="data">
            <b-media vertical-align="center" class="align-items-center">
              <template #aside>
                <b-avatar
                  :id="`fuel-row-${data.item.number}`"
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.issuerName)"
                  :variant="`light-${resolveClientAvatarVariant(data.item.directionId)}`"
                />
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.issuerName ? data.item.issuerName : '-' }}
              </span>
              <b-tooltip
                :target="`fuel-row-${data.item.number}`"
                placement="top"
              >
                <p class="mb-0">
                  {{ data.item.issuerName }}
                </p>
                <p class="mb-0">
                  {{ data.item.accountName }}
                </p>
                <p class="mb-0">
                  {{ data.item.tradePointName }}
                </p>
                <p class="mb-0">
                  {{ data.item.tradePointAddress }}
                </p>
              </b-tooltip>
            </b-media>
          </template>

          <template #cell(typeAccount)="data">
            <b-badge
              pill
              :variant="`light-${resolveCashVariant(data.item.typeAccount)}`"
              class="text-capitalize"
              v-if="data.item.typeAccount"
            >
              {{ resolveCash(data.item.typeAccount) }}
            </b-badge>
          </template>

          <template #cell(isExecuted)="data">
            <b-badge
              pill
              :variant="`light-${resolveStatusVariant(data.item.isExecuted)}`"
              class="text-capitalize"
            >
              {{ resolveStatus(data.item.isExecuted) }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">
              <feather-icon
                :id="`fuel-row-${data.item.number}-preview-icon`"
                icon="EyeIcon"
                size="16"
                class="mx-1"
                @click="showModal(data.item)"
              />
              <b-tooltip
                :target="`fuel-row-${data.item.number}-preview-icon`"
              />

              <!-- Dropdown -->
              <b-dropdown
                variant="link"
                toggle-class="p-0"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="showModal(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Переглянути</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Показано {{ dataMeta.from }} до {{ dataMeta.to }} з {{ dataMeta.of }} записів</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalInvoices"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>

        <b-modal
          v-model="modalShow"
          :title="'Транзакція #'+modalItem.number"
          ok-title="Закрити"
          ok-only
        >

          <h4 class="mb-2">Транзакція #{{ modalItem.number }}</h4>
          <div class="invoice-date-wrapper">
            <p class="invoice-date-title">
              Дата операції:
            </p>
            <p class="invoice-date">
              {{ modalItem.executedAt }}
            </p>
          </div>
          <div class="invoice-date-wrapper mb-2">
            <p class="invoice-date-title">
              Водій:
            </p>
            <p class="invoice-date">
              {{ modalItem.driverFullName }}
            </p>
          </div>

          <h5 class="mb-1">ОПИС</h5>
          <p class="card-text mb-25">
            {{ modalItem.issuerName }}
          </p>
          <p class="card-text mb-25">
            {{ modalItem.accountName }}
          </p>
          <p class="card-text mb-25">
            {{ modalItem.tradePointName }}
          </p>
          <p class="card-text mb-3">
            {{ modalItem.tradePointAddress }}
          </p>

          <h5 class="mb-1">СУМА</h5>
          <div class="invoice-date-wrapper">
            <p class="invoice-date-title">
              Кількість:
            </p>
            <p class="invoice-date">
              {{ (modalItem.amount / 1000).toFixed(2) }} л
            </p>
          </div>
          <div class="invoice-date-wrapper">
            <p class="invoice-date-title">
              Ціна:
            </p>
            <p class="invoice-date">
              {{ (modalItem.price / 100).toFixed(2) }} грн
            </p>
          </div>
          <div class="invoice-date-wrapper">
            <p class="invoice-date-title">
              Всього:
            </p>
            <p class="invoice-date">
              {{ (modalItem.sum / 100).toFixed(2) }} грн
            </p>
          </div>
          
        </b-modal>
      </b-card>

    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BCard, BTable, BPagination, BAvatar, BTooltip, BMedia, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import driverStoreModule from '../driverStoreModule'
import useInvoicesList from './useFuelList'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    vSelect,
    BTable,
    BPagination,
    BAvatar,
    BTooltip, 
    BMedia,
    BBadge, 
    BDropdown, 
    BDropdownItem,
  },
  data() {
    return {
      modalShow: false,
      modalItem: [],
    }
  },  
  methods: {
    showModal(item) {
      this.modalItem = item
      this.modalShow = !this.modalShow
    },
  },
  setup() {
    const driverData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-driver'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, driverStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFuelListTable,

      statusFilter,
      driverFilter,
      cashTypeFilter,

      statusOptions,
      cashTypeOptions,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,

      resolveCashVariant,
      resolveCash,

      resolveStatusVariant,
      resolveStatus,
      getDrivers,

    } = useInvoicesList(router.currentRoute.params.id)

    return {

      driverData,

      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFuelListTable,

      statusFilter,
      driverFilter,
      cashTypeFilter,

      refetchData,

      statusOptions,
      cashTypeOptions,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,

      resolveCashVariant,
      resolveCash,

      resolveStatusVariant,
      resolveStatus,
    }
  },
}
</script>

<style scoped>

.invoice-date-wrapper {
  display: flex;
  align-items: center;
}

.invoice-date-title {
  font-weight: 600;
  margin-right: 10px;
}

</style>
