import { onBeforeMount, computed } from '@vue/composition-api';
import store from "@/store";
import { useStoreModule } from "@/composition/useStoreModule";
import { extraStoreModule } from "@/store/extra/extraStore.module";

const STORE_MODULE_NAME = 'extraFuels';

const fuelsTranslations = {
    'dp': 'Дизель',
    'dpplus': 'Дизель+',
    '92': 'А-92',
    '95': 'А-95',
    '95plus': 'А-95+',
    '98': 'А-98',
    '100': 'А-100',
    'lpg': 'Газ',
}

export function useFuels() {

    useStoreModule(STORE_MODULE_NAME, extraStoreModule);

    const fetchFuels =  async () => {
        await store.dispatch(`${STORE_MODULE_NAME}/fetchFuels`);
    };

    const fuels = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_FUELS`];
    });

    function getFuelsTitle(key) {
        return fuelsTranslations[key];
    }

    onBeforeMount(async() => {
        await fetchFuels();
    });

    return { fuels, getFuelsTitle };

}