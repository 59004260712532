<template>
  <app-collapse class="mt-2">

    <app-collapse-item title="Ліміти">

      <b-form-group
          label="Відображати ліміт у водія"
          for="displayableTypePeriod"
          class="mt-1"
      >
        <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="account.currentCard.limits.displayableTypePeriod"
            :options="displayableTypePeriodsOptions"
            class="w-100"
            :reduce="val => val.value"
            name="displayableTypePeriod"
            :clearable="false"
            :aria-disabled="disabledAccount"
            @input="(val) => account.currentCard.limits.displayableTypePeriod = val"

        />
      </b-form-group>

      <table class="limits-table mt-1">
        <thead>
        <tr>
          <th></th>
          <th>Паливні</th>
          <th>Грошові</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>На день</td>
          <td>
            <div class="d-flex align-items-center justify-content-end">
              <span>{{ account.currentCard.limits.goods.dayLimitRest }}  / </span>
              <b-form-input
                  class="limit-input"
                  v-model="account.currentCard.limits.goods.dayLimitValue"
                  :disabled="disabledAccount"
                  @input="changeLimit(account.id, 'day', 'goods')"
              />
              <span>л</span>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center justify-content-end">
              <span>{{ account.currentCard.limits.money.dayLimitRest }}  / </span>
              <b-form-input
                  class="limit-input"
                  v-model="account.currentCard.limits.money.dayLimitValue"
                  :disabled="disabledAccount"
                  @input="changeLimit(account.id, 'day', 'money')"
              />
              <span>грн</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>На тиждень</td>
          <td>
            <div class="d-flex align-items-center justify-content-end">
              <span>{{ account.currentCard.limits.goods.weekLimitRest }}  / </span>
              <b-form-input
                  class="limit-input"
                  v-model="account.currentCard.limits.goods.weekLimitValue"
                  :disabled="disabledAccount"
                  @input="changeLimit(account.id, 'week', 'goods')"
              />
              <span>л</span>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center justify-content-end">
              <span>{{ account.currentCard.limits.money.weekLimitRest }}  / </span>
              <b-form-input
                  class="limit-input"
                  v-model="account.currentCard.limits.money.weekLimitValue"
                  :disabled="disabledAccount"
                  @input="changeLimit(account.id, 'week', 'money')"
              />
              <span>грн</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>На місяць</td>
          <td>
            <div class="d-flex align-items-center justify-content-end">
              <span>{{ account.currentCard.limits.goods.monthLimitRest }}  / </span>
              <b-form-input
                  class="limit-input"
                  v-model="account.currentCard.limits.goods.monthLimitValue"
                  :disabled="disabledAccount"
                  @input="changeLimit(account.id, 'month', 'goods')"
              />
              <span>л</span>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center justify-content-end">
              <span>{{ account.currentCard.limits.money.monthLimitRest }}  / </span>
              <b-form-input
                  class="limit-input"
                  v-model="account.currentCard.limits.money.monthLimitValue"
                  :disabled="disabledAccount"
                  @input="changeLimit(account.id, 'month', 'money')"
              />
              <span>грн</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>Загальні</td>
          <td>
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input class="limit-input" v-model="account.currentCard.limits.goods.totalLimitValue" /> -->
              <span>{{ account.currentCard.limits.goods.totalLimitRest }} л</span>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input class="limit-input" v-model="account.currentCard.limits.money.totalLimitValue" /> -->
              <span>{{ account.currentCard.limits.money.totalLimitRest }} грн</span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <b-button
          :variant="disabledAccount ? '' : 'success'"
          :disabled="disabledAccount"
          @click="saveLimitsFuels(account.currentCard.limits, account.currentCard.fuels, driverData.profileId, account.typeAccount)"
      >
        Зберегти
      </b-button>
    </app-collapse-item>

  </app-collapse>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {BButton, BFormGroup, BFormInput} from "bootstrap-vue";
import vSelect from "vue-select";
import { useSaveLimits } from "@/views/apps/driver/drivers-view/useSaveLimits";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    AppCollapse,
    AppCollapseItem,
    vSelect,
  },
  props: {
    account: {
      type: Object,
      default: () => {},
      required: true,
    },
    driverData: {
      type: Object,
      required: true,
    },
    disabledAccount: {
      type: Boolean,
      default: true
    },
  },
  setup(props) {
    const { driverData } = props;
    const displayableTypePeriodsOptions = [
      { label: 'За замовчуванням', value: 0 },
      { label: 'За день', value: 1 },
      { label: 'За тиждень', value: 2 },
      { label: 'За місяць', value: 3 },
      { label: 'За весь час', value: 4 },
    ];

    const changeLimit = (cardId, period, type) => {

      if (period == 'day') {
        if (type == 'goods') {
          driverData.wallet.accounts[cardId].currentCard.limits.money.dayLimitActive = false
          driverData.wallet.accounts[cardId].currentCard.limits.money.dayLimitValue = 0
          driverData.wallet.accounts[cardId].currentCard.limits.goods.dayLimitActive = true
        } else if (type == 'money') {
          driverData.wallet.accounts[cardId].currentCard.limits.goods.dayLimitActive = false
          driverData.wallet.accounts[cardId].currentCard.limits.goods.dayLimitValue = 0
          driverData.wallet.accounts[cardId].currentCard.limits.money.dayLimitActive = true
        }
      } else if (period == 'week') {
        if (type == 'goods') {
          driverData.wallet.accounts[cardId].currentCard.limits.money.weekLimitActive = false
          driverData.wallet.accounts[cardId].currentCard.limits.money.weekLimitValue = 0
          driverData.wallet.accounts[cardId].currentCard.limits.goods.weekLimitActive = true
        } else if (type == 'money') {
          driverData.wallet.accounts[cardId].currentCard.limits.goods.weekLimitActive = false
          driverData.wallet.accounts[cardId].currentCard.limits.goods.weekLimitValue = 0
          driverData.wallet.accounts[cardId].currentCard.limits.money.weekLimitActive = true
        }
      } else if (period == 'month') {
        if (type == 'goods') {
          driverData.wallet.accounts[cardId].currentCard.limits.money.monthLimitActive = false
          driverData.wallet.accounts[cardId].currentCard.limits.money.monthLimitValue = 0
          driverData.wallet.accounts[cardId].currentCard.limits.goods.monthLimitActive = true
        } else if (type == 'money') {
          driverData.wallet.accounts[cardId].currentCard.limits.goods.monthLimitActive = false
          driverData.wallet.accounts[cardId].currentCard.limits.goods.monthLimitValue = 0
          driverData.wallet.accounts[cardId].currentCard.limits.money.monthLimitActive = true
        }
      }
    };

    const { saveLimitsFuels } = useSaveLimits();

    return { displayableTypePeriodsOptions, changeLimit, saveLimitsFuels };
  }
}
</script>